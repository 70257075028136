import styled from 'styled-components';

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;


export const MessageBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
  margin-top: 20px;
  height: 75%;
  min-height: 470px;
  min-width: 450px;
`;

export const MessageBoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 360px;
  height: 95%;
  background-color: #e3e3e3;
  border-radius: 10px 0 0 10px;
  padding: 20px 20px;
  color: darkblue;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderTitle = styled.span`
  margin: 5px 0px;
  display: flex;
  flex-direction: column;
`;

export const HeaderData = styled.span`
  font-weight: bold;
`;

export const MessagesContainer = styled.div`
  width: 100%;
  height: 95%;
  background-color: #f3f3f3;
  border-radius: 0 10px 10px 0;
`;

export const MessageBoxContent = styled.div`
  flex: 1;
  display: flex;
  overflow: auto;
  height: calc(100% - 55px);
  flex-direction: column;
  vertical-align: bottom;
  padding: 30px 30px 30px 30px;
`;

export const MessageFromUser = styled.div`
  max-width: 90%;
  padding: 8px 15px;
  background-color: #0a7e7e;
  border-radius: 10px;
  margin-top: 20px;
  align-self: flex-end;
  position: relative;
`;

export const MessageFromClient = styled.div`
  max-width: 90%;
  padding: 8px 15px;
  background-color: #527494;
  border-radius: 10px;
  align-self: flex-start;
  margin-top: 10px;
  position: relative;
`;

interface UserNameProps {
  defineWidth: number;
}

export const UserName = styled.div`

  font-size: 12px;
  color: #f3f3f3;
  margin-right: 5px;
`;

export const TextInputContainer = styled.div`
  display: flex;
  align-items: center;
  height: 55px;
  width: 100%;
  position: relative;
  background-color: #a3a3a3;

`;

export const TextInput = styled.input`
  padding: 0 15px;
  width: 92%;
  border: none;
  height: 40px;
  border: 1px solid #c3c3c3;
  border-radius: 25px;
  margin-left: 10px;
`;

export const SendButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  margin-left: 10px;
  transition: all 0.2s;

  &:hover{
    opacity: 0.8;
  }
`;
