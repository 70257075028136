import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import SignIn from '../pages/SignIn';
import RecoverPassword from '../pages/RecoverPassword';
import RegisterUser from '../pages/RegisterUser';
import NewPassword from '../pages/NewPassword';
import Dashboard from '../pages/Dashboard';
import ManagerUsers from '../pages/ManagerUsers';
import Messages from '../pages/Messages';

const AdminRoutes: React.FC = () => {
  return(
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/recoverpassword" exact component={RecoverPassword} />
      <Route path="/newpassword" component={NewPassword} isPrivate />
      <Route path="/registeruser" component={RegisterUser} isPrivate />
      <Route path="/managerusers" component={ManagerUsers} isPrivate />
      <Route path="/message/:id" component={Messages} isPrivate />
    </Switch>
  );
};

export default AdminRoutes;
