import React from 'react';
import { Switch, RouteComponentProps } from 'react-router-dom';

import Route from './Route';
import SignIn from '../pages/SignIn';
import RecoverPassword from '../pages/RecoverPassword';
import NewPassword from '../pages/NewPassword';
import Dashboard from '../pages/Dashboard';
import Messages from '../pages/Messages';
import { useAuth } from '../hooks/auth';

const EmployeeRoutes: React.FC = () => {
  return(
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/recoverpassword" exact component={RecoverPassword} />
      <Route path="/newpassword" component={NewPassword} isPrivate />
      <Route path="/message/:id" component={Messages} isPrivate />
    </Switch>
  );
};

export default EmployeeRoutes;
