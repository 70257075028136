import styled, { keyframes, css } from 'styled-components';
import { shade } from 'polished';
import signUpBackgroundImg from '../../assets/sign-up-background.png';
import Tooltip from '../../components/Tooltip';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;

  a{
    color: #1c195d;
    margin-right: 15px;
    margin-left: 10px;
    margin-top:20px;
    display: block;
    align-self: flex-start;

    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg{
      margin-right: 5px;
      align-self: center;
    }

    &:hover{
        color: ${shade(0.2, '#1c195d')}
      }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 700px;
`;

const appearFromRight = keyframes`
  from{
    opacity: 0;
    transform: translateX(50px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img{
    height: 10vh;
  }

  animation: ${appearFromRight} 1s;

  form{
      margin: 30px 0;
      width: 340px;
      text-align: center;
    }

    h1{
      color: #1c195d;
      margin-bottom: 24px;
    }

    a{
      color: #1C195D;
      display: block;
      margin-top: 10px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover{
        color: ${shade(0.2, '#1C195D')}
      }
    }

    > a {
      color: #252422;
      display: flex;
      margin-top: 10px;
      text-decoration: none;
      transition: color 0.2s;

      display: flex;
      align-items: center;

      svg{
        margin-right: 16px;
      }

      &:hover{
        color: ${shade(0.2, '#252422')}
      }
    }

    .ReactLoading {
      display: inline-table;
      align-items:center;
      justify-content:center;
    }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signUpBackgroundImg}) no-repeat center;
  background-size: cover;
`;

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored?: boolean;
}

export const CustomSelect = styled.select<ContainerProps>`

  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  background: #CBC8D4 ;
  border-radius: 10px;
  padding: 16px;

  border: 2px solid #CBC8D4;
  color: #666360;

  ${props => props.isErrored && css`
    border-color: #c53030;
  `}

  ${props => props.isFocused && css`
    color: #1C195D;
    border-color: #1C195D;
  `}

  ${props => props.isFilled && css`
    color: #1C195D;
  `}
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg{
    margin:0;
  }

  span{
    background: #C53030;
    color: #FFF;

    &::before{
      border-color: #C53030 transparent;
    }
  }

`;
