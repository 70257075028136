import styled, { css } from 'styled-components';



export const Container = styled.div`
    width: 80%;
    padding-bottom: 80px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
`;
export const TableContainer = styled.table`
  border-spacing: 3px 10px;
`;

export const TableHeader = styled.th`
    font-size: 18px;
    padding: 15px 20px;
    background-color: #34495e;
    font-weight: 400;
    border-radius: 10px;
    color: white;

`;

export const TableH = styled.tr`
  margin: 2px;
`;

export const TableD = styled.td`
    padding: 10px 20px;
    text-align: center;
    border-radius: 6px;
    background-color: rgb(224, 224, 224);
    color: #34495e;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.63);
`;

