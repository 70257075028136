import React from 'react';
import { Switch } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { useAuth } from '../hooks/auth';

import Route from './Route';
import AdminRoutes from './admin.routes';
import EmployeeRoutes from './employee.routes';
import SignIn from '../pages/SignIn';

const Routes: React.FC = () => {
  
  const { user, userRole } = useAuth();
  
  if (userRole === "Administrator") {    
    return (      
      <AdminRoutes />
    )
  } else if (userRole === "Employee") {    
    return (
      <EmployeeRoutes />
    )
  }
  
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
    </Switch>
  )
};

export default Routes;
