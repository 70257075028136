import React, { useState, useEffect, createRef, useCallback } from 'react';
import api from '../../services/api';
import { FiSend } from 'react-icons/fi';
import Header from '../../components/Header';
import { useParams } from 'react-router';
import {
  AppContainer, MessageBox, MessageBoxHeader,
  HeaderContent, HeaderTitle, HeaderData, MessageBoxContent,
  MessageFromUser, MessageFromClient, TextInput, MessagesContainer,
  TextInputContainer, SendButton, UserName
} from './styles';

import { Message, User } from '../../interfaces';
import { formatDate } from '../../utils';

const Messages: React.FC = () => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<Array<Message>>([]);
  const [processId, setProcessId] = useState("");
  const [user, setUser] = useState<User>({} as User);

  const [userData, setUserData] = useState({
    username: localStorage.getItem('@AppBern:user'),
    token: localStorage.getItem('@AppBern:token'),
    email: localStorage.getItem('@AppBern:email'),
  });

  const messagesEndRef: React.RefObject<HTMLDivElement> = createRef();

  const code = window.location.pathname.split('/')[2];

  useEffect(() => {
    getMessages(code);
    getUser();
  }, []);

  useEffect(() => {
    getProcessId();
    scrollToBottom();
  }, [messages]);

  const getUser = async () => {
    const config = {
      headers: {
        'Authorization': `Bearer ${userData.token}`
      }
    }
    try {
      console.log(userData.email);
      const result = await api.get(`/api/User/GetByEmail?email=${userData.email}`, config);
      setUser(result.data);
    } catch (error) {
      console.log("ERROR(UserData:Messages):", error);
    }
  }

  const getProcessId = async () => {
    if (messages.length > 0) {
      setProcessId(messages[0].processo.id.toString());
    }
  }

  const getMessages = async (code: string) => {
    const config = {
      headers: {
        'Authorization': `Bearer ${userData.token}`
      }
    }
    try {
      const res = await api.get(`/api/Process/GetMessagesByCode?code=${code}`, config);
      setMessages(res.data);
    } catch (error) {
      console.log("ERROR (Messages):", error);
    }
  }

  const scrollToBottom = () => {
    if (messagesEndRef.current != null) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setMessage(e.currentTarget.value);
  }

  const sendMessage = async () => {
    if (message === "") return;

    let messageBody = {
      "processo": {
        "id": Number(processId)
      },
      "user": {
        "id": user.id
      },
      "message": message,
      "createdDate": new Date().toISOString(),
    }

    let tempMessages = messages;
    //@ts-ignore
    tempMessages.push(messageBody);
    setMessages(tempMessages);

    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${userData.token}`
        }
      }

      await api.post(`/api/Process/CreateMessage`, messageBody, config);
      setMessage("");
    } catch (error) {
      console.log("ERROR:(CreateMessage: Messages):", error);
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  }

  return (
    <AppContainer>
      <Header />
      {messages.length <= 0 ? <div style={{ height: "100%" }}>Nenhuma mensagem encontrada para esse processo, ou o processo não existe!</div> :
        (
          <MessageBox>
            <MessageBoxHeader>
              <HeaderContent>
                <HeaderTitle>
                  Processo: <HeaderData> {messages[0].processo.processNumber} </HeaderData>
                </HeaderTitle>
                <HeaderTitle>
                  Cliente: <HeaderData> {messages[0].processo.customer.description} </HeaderData>
                </HeaderTitle>
                <HeaderTitle>
                  Reclamante: <HeaderData> {messages[0].processo.complainer} </HeaderData>
                </HeaderTitle>
                <HeaderTitle>
                  Entrada: <HeaderData> {formatDate(messages[0].processo.entrydate)} </HeaderData>
                </HeaderTitle>
                <HeaderTitle>
                  Prazo: <HeaderData> {messages[0].processo.dueDate == null ? '-' : formatDate(messages[0].processo.dueDate)} </HeaderData>
                </HeaderTitle>
                <HeaderTitle>
                  Prazo Negociado: <HeaderData> {messages[0].processo.negotiatedDueDate == null ? '-' : formatDate(messages[0].processo.negotiatedDueDate)} </HeaderData>
                </HeaderTitle>
                <HeaderTitle>
                  Status: <HeaderData> {messages[0].processo.status} </HeaderData>
                </HeaderTitle>
              </HeaderContent>
            </MessageBoxHeader>

            <MessagesContainer>
              <MessageBoxContent id="message-box">
                {messages.map(m => {
                  return (
                    m.user.email == userData.email || (m.user.email == null && m.processo.entrydate == null) ? (
                      <MessageFromUser key={m.id != null ? m.id : -1}>
                        <UserName>
                          {user.username}:
                        </UserName>
                        {m.message}
                      </MessageFromUser>
                    ) : (
                        <MessageFromClient key={m.id}>
                          <UserName>
                            {m.user.username}:
                          </UserName>
                          {m.message}
                        </MessageFromClient>
                      )
                  )
                })}

                <div ref={messagesEndRef} />
              </MessageBoxContent>

              <TextInputContainer>
                <TextInput onKeyDown={handleKeyDown} onChange={handleInputChange} value={message} type="text" placeholder="Digite sua mensagem!" />
                <SendButton onClick={() => sendMessage()}>
                  <FiSend style={{ marginRight: 8 }} size={28} />
                </SendButton>
              </TextInputContainer>
            </MessagesContainer>
          </MessageBox>
        )}
    </AppContainer>
  );

};

export default Messages;
