import React from 'react';
import { useHistory} from 'react-router-dom';
import { Link } from 'react-router-dom';
import iconAddUser from '../../assets/iconAddUser.png';
import iconManageUser from '../../assets/iconManagerUser.png';

import Header from '../../components/Header';

import { Content, AppContainer } from './styles';

const Dashboard: React.FC = () => {

  const urlDestino = localStorage.getItem('@AppBern:urlDestino');
  const history = useHistory();

  if(urlDestino){
    localStorage.removeItem('@AppBern:urlDestino');
    history.push(urlDestino);
  }

  return (
    <AppContainer>
      <Header />

      <Content>

        <Link to="registeruser">
          <img src={iconAddUser} />
        </Link>

        <Link to="managerusers">
          <img src={iconManageUser} />
        </Link>

      </Content>
    </AppContainer>
  );

};

export default Dashboard;
