import React, {useEffect, useState} from 'react';
import api from '../../services/api';

import { Container, TableContainer, TableHeader, TableD, TableH } from './styles.js';
import Switch from "react-switch";
import { useToast } from '../../hooks/toast';
import { checkServerIdentity } from 'tls';

interface User {
  id: number,
  profile:{
    description: string
  },
  username: string,
  email: string,
  status: string
}

const TabelaRelatorio = (usersObject: any) => {
  const users = usersObject.users;
  const token = localStorage.getItem('@AppBern:token');
  const { addToast } = useToast();

  const updateUsers = (user: User) => {
    usersObject.updateUser(user.id)
    changeStatusUser(user);
  }

  const [stateUsers, setStateUsers] = useState(users);


  async function changeStatusUser(user :User){
    try{
      const config = {
        headers: {
          'Authorization' : `Bearer ${token}`
        }
      }

      const newStatus = user.status === "ATIVO" ? false : true;

      await api.post('/api/User/ChangeStatus',{ email: user.email , status: newStatus }, config);

      addToast({
        type: 'success',
        title: 'Status alterado',
        description: `Status do usuário ${user.username} alterado para ${newStatus}.`
      });

      window.location.reload();

    }catch{

        addToast({
          type: 'error',
          title: 'Status não alterado',
          description: `Não foi possível alterar o status do usuário ${user.username}.`
        });
    }
  };

  return (
    <>
      <Container className="conteudo-tabela">
        <TableContainer className="tabela">
          <tbody>
            <TableH>
              <TableHeader className="cabecalho-tabela">Descrição</TableHeader>
              <TableHeader className="cabecalho-tabela">E-mail</TableHeader>
              <TableHeader className="cabecalho-tabela">Tipo</TableHeader>
              <TableHeader className="cabecalho-tabela">Ativo</TableHeader>
            </TableH>

            {users.length > 0 ? (
              users.map((u: User) => {
                return (
                  <TableH key={u.id}>
                    <TableD >{u.username}</TableD>
                    <TableD>{u.email}</TableD>
                    <TableD>{u.profile.description}</TableD>
                    <TableD>
                        <Switch defaultChecked onColor="#008" onChange={() => updateUsers(u)} checked={ u.status == 'ATIVO' ? true : false} /> :
                    </TableD>
                  </TableH>
                )
              })
            ) : ''}
          </tbody>
        </TableContainer>
      </Container>
    </>
  );
};

export default TabelaRelatorio;
