import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.select<ContainerProps>`

  width: 100%;
  margin-top: 8px;
  background: #CBC8D4 ;
  border-radius: 10px;
  padding: 16px;
  font-family: 'Roboto Slab', serif;
  font-size: 16px;

  border: 2px solid #CBC8D4;
  color: #666360;

  & + select{
    margin-top: 8px;
  }
  ${props => props.isErrored && css`
    border-color: #c53030;
  `}

  ${props => props.isFocused && css`
    color: #1C195D;
    border-color: #1C195D;
  `}

  ${props => props.isFilled && css`
    color: #1C195D;
  `}
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg{
    margin:0;
  }

  span{
    background: #C53030;
    color: #FFF;

    &::before{
      border-color: #C53030 transparent;
    }
  }

`;
