import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FiLogIn, FiLogOut, FiSearch, FiHome } from 'react-icons/fi';
import { HeaderContainer, HeaderOptions } from './styles';
import logoImg from '../../assets/logoCalc.png'
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

const Header: React.FC = () => {
  const { signOut } = useAuth();
  const { addToast } = useToast();
  const handleSignOut = useCallback(async () => {
    try {

      await signOut();

    } catch (error) {

      addToast({
        type: 'error',
        title: 'Erro na autenticação',
        description: 'Ocorrreu um erro ao fazer login, cheque as credenciais'
      });

    }
  }, [signOut])

  return (
    <HeaderContainer>
      <img src={logoImg} alt="Bernhoeft" />
      <HeaderOptions>
        <Link to="/"><FiHome />Início</Link>
        <Link onClick={() => handleSignOut()} to=""><FiLogOut />Sair</Link>
      </HeaderOptions>
    </HeaderContainer>
  );

};

export default Header;
