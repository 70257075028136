import React, { useCallback, useRef, useState, useEffect } from 'react'; //Usar useCallback
import { Link, useHistory } from 'react-router-dom';
import { FiArrowLeft, FiMail, FiUser, FiHome, FiLogOut, FiPhone, FiUsers } from 'react-icons/fi';
import ReactLoading from 'react-loading';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup'; //Biblioteca de validação
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import getValidationErrors from '../../utils/getValidationErrors';
import logoImg from '../../assets/logo.png';
import Input from '../../components/Input'
import Button from '../../components/Button'
import SelectCustom from '../../components/Select'
import { Container, Content, AnimationContainer, Background, CustomSelect } from './styles';

interface OptionsProps {
  id: number;
  description: string;
}

interface RegisterFormData {
  description: string;
  email: string;
  phone: string;
  profile: string;
  telefone: string;
  tipo: string;
  customer?: string;
  office?: string;
}

const RegisterUser: React.FC = () => {
  const [currentProfile, setCurrentProfile] = useState('');
  const [profiles, setProfiles] = useState<OptionsProps[]>([]);
  const [offices, setOffices] = useState<OptionsProps[]>([]);
  const [customers, setCustomers] = useState<OptionsProps[]>([]);
  const [loadingRegister, setLoadingRegister] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const { signOut } = useAuth();
  const token = localStorage.getItem('@AppBern:token');

  useEffect(() => {
    async function loadStorageData():Promise<void> {
      getProfiles();
      getOffices();
      getCustomers();
    }

    loadStorageData();
  },[])

  const config = {
    headers: {
      'Authorization' : `Bearer ${token}`
    }
  }

  async function getProfiles():Promise<void>{
    const resProfiles = await api.get('/api/User/ProfilesList', config);
    setProfiles(resProfiles.data);
  }

  async function getOffices():Promise<void>{
    const resOfficess = await api.get('/api/Office', config);
    setOffices(resOfficess.data);
  }

  async function getCustomers():Promise<void>{
    const resCustomers = await api.get('/api/Customer', config);
    setCustomers(resCustomers.data);
  }

  const handleSignOut = useCallback(async () => {
    try {

      await signOut();

    } catch (error) {

      addToast({
        type: 'error',
        title: 'Erro na autenticação',
        description: 'Ocorrreu um erro ao fazer login, cheque as credenciais'
      });

    }
  }, [signOut])

  const handleSubmit = useCallback(async (data: RegisterFormData) => {
    try {

      setLoadingRegister(true);

      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        description: Yup.string().required('Descrição obrigatória'),
        email: Yup.string().required('E-mail obrigatório').email('Digite um e-mail válido'),
        profile: Yup.string().required('Perfil obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const token = localStorage.getItem('@AppBern:token');

      const config = {
        headers:{
          'Authorization': `Bearer ${token}`
        }
      }

      const result = await api.get(`/api/User/GetByEmail?email=${data.email}`, config);

      if(!!result.data){
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'O usuário solicitado já existe.'
        });

        setLoadingRegister(false);

        return;
      }

      await api.post('api/User/createuser', {
        profile: {
          id: parseInt(data.profile)
        },
        customer:{
          id: data.customer === undefined ? 0 : parseInt(data.customer)
        },
        office:{
          id: data.office === undefined ? 0 : parseInt(data.office)
        },
        username: data.description,
        email: data.email
      }, config);

      setLoadingRegister(false);

      addToast({
        type: 'success',
        title: 'Cadastro realizado',
        description: 'Usuário cadastrado com sucesso.'
      });

      formRef.current?.reset();

      history.push("/registeruser");
    } catch (err) {

      setLoadingRegister(false);

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description: 'Ocorrreu um erro ao fazer cadastro, tente novamente.'
      });
    }
  }, [addToast]);

  const handleFormChange = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (event.target.value == 2) {
      console.log("Office");
      // Load Offices
      setCurrentProfile("office");
    } else if (event.target.value == 1) {
      console.log("Customer");
      // Load Clients
      setCurrentProfile("customer");
    } else if (event.target.value == 3) {
      // Load nothing
      setCurrentProfile("profile");
    }
  }
  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Bernhoeft" />

          <Form ref={formRef} onSubmit={handleSubmit} onChange={handleFormChange}>
            <h1>Cadastrar Usuário</h1>

            <Input name="description" icon={FiUser} placeholder="Descrição" />
            <Input name="email" icon={FiMail} placeholder="E-mail" />

            <SelectCustom
              name="profile"
              defaulSelection={profiles[0]}
              options={profiles}
            />

            {currentProfile == "profile" || currentProfile == "" ? (<></>) : currentProfile == "office" ?
              (<>
                {/* Carregar escritórios */}
                <SelectCustom
                  name="office"
                  defaulSelection={offices[0]}
                  options={offices}
                />
              </>) :
              (<>
                {/* Carregar clientes */}
                <SelectCustom
                  name="customer"
                  defaulSelection={customers[0]}
                  options={customers}
                />
              </>)}

            {loadingRegister && <ReactLoading className="ReactLoading" type="bars" color="#1C195D" width={'20%'}/>}
            {!loadingRegister && <Button type="submit" >Cadastrar</Button>}

            <Link to="dashboard">
              <FiArrowLeft />
              Voltar para Dashboard
            </Link>
          </Form>
        </AnimationContainer>
      </Content>

      <Link to="dashboard"><FiHome />Início</Link>
      <Link onClick={() => handleSignOut()} to=""><FiLogOut />Sair</Link>

    </Container>
  );
};

export default RegisterUser;
