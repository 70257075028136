import React, {useCallback, useRef, useState} from 'react'; //Usar useCallback
import ReactLoading from 'react-loading';
import { FiMail, FiLock } from 'react-icons/fi';
import {FormHandles} from '@unform/core';
import {Form} from '@unform/web';
import * as Yup from 'yup'; //Biblioteca de validação
import { useHistory} from 'react-router-dom';
import {useAuth} from '../../hooks/auth';
import {useToast} from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import logoImg from '../../assets/logo.png';
import Input from '../../components/Input'
import Button from '../../components/Button'
import {Container, Content, AnimationContainer, Background} from './styles';

interface SignInFormData{
  userName: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loadingLogin, setLoadingLogin] = useState(false);

  const {signIn} = useAuth();
  const {addToast} = useToast();

  const history = useHistory();

  const handleSubmit = useCallback(async (data: SignInFormData) => {
    try {

      setLoadingLogin(true);

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        userName: Yup.string().required('E-mail obrigatório').email('Digite um e-mail válido'),
        password: Yup.string().required('Senha obrigatória')
      });

      await schema.validate(data,{
        abortEarly: false,
      });

      await signIn({
        userName: data.userName,
        password: data.password
      });

      setLoadingLogin(false);
    } catch (err) {
      setLoadingLogin(false);

      if(err instanceof Yup.ValidationError){
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Erro na autenticação',
        description: 'Ocorrreu um erro ao fazer login, cheque as credenciais'
      });
    }
  }, [signIn, addToast, history]);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Bernhoeft"/>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Faça seu login</h1>

            <Input name="userName" icon={FiMail} placeholder="E-mail"/>
            <Input name="password" icon={FiLock} placeholder="Senha" type="password"/>

            {loadingLogin && <ReactLoading className="ReactLoading" type="bars" color="#1C195D" width={'20%'}/>}
            {!loadingLogin && <Button type="submit" >Entrar</Button>}

            <a href="recoverpassword">Esqueci minha senha</a>
          </Form>

        </AnimationContainer>
      </Content>

      <Background />
    </Container>
  );
}

export default SignIn;
