

export const formatDate = (date: Date, addDay: boolean = true) => {
  if (date != null && date != undefined) {
    let dateNew1 = new Date(date);
    let dateNew = new Date(dateNew1.toISOString());
    if ((24 - dateNew.getHours()) > (dateNew.getTimezoneOffset() / 60)) {
      dateNew.setHours(0, 0, 0, 0);
    } else {
      dateNew.setDate(dateNew.getDate() + (addDay ? 1 : 0));
    }
    let day = dateNew.getDate();
    let finalDay = "";
    let month = dateNew.getMonth();
    let finalMonth = "";

    const year = dateNew.getFullYear();

    if (year == 1901) {
      return "-";
    }

    if (day < 10) {
      finalDay = "0" + day;
    } else {
      finalDay = "" + day;
    }

    if (month + 1 < 10) {
      finalMonth = "0" + (month + 1);
    } else {
      finalMonth = "" + (month + 1);
    }

    return finalDay + "/" + finalMonth + "/" + year;
  }

}
