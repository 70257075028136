import styled,{keyframes} from 'styled-components';
import {shade} from 'polished';
import signInBackgroundImg from '../../assets/sign-in-background.png';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 700px;
`;

const appearFromLeft = keyframes`
  from{
    opacity: 0;
    transform: translateX(-50px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img{
    height: 10vh;
  }

  animation: ${appearFromLeft} 1s;

  form{
      margin: 80px 0;
      width: 340px;
      text-align: center;
      align-items:center;
      justify-content:center;
    }

    h1{
      color: #1c195d;
      margin-bottom: 24px;
    }

    a{
      color: #1C195D;
      display: block;
      margin-top: 1px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover{
        color: ${shade(0.2, '#1C195D')}
      }
    }

    > a {
      color: #252422;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      display: flex;
      align-items: center;

      svg{
        margin-right: 16px;
      }

      &:hover{
        color: ${shade(0.2, '#252422')}
      }
    }

    .ReactLoading {
      display: inline-table;
      align-items:center;
      justify-content:center;
    }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signInBackgroundImg}) no-repeat center;
  background-size: cover;
`;
