import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useToast } from '../../hooks/toast';

import Input from '../../components/Input'
import Button from '../../components/Button'
import Table from '../../components/Table';
import Header from '../../components/Header';

import { Content, SearchContainer, HeaderLine, AppContainer } from './styles';
import api from '../../services/api';

interface FilterSearchData {
  campopesquisar: string;
}

interface Users {
  id: number,
  profile:{
    description: string
  },
  username: string,
  email: string,
  status: string
}

const ManagerUsers: React.FC = () => {

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [users, setUsers] = useState<Users[]>([]);
  const token = localStorage.getItem('@AppBern:token');

  useEffect(() => {
    async function loadStorageData():Promise<void> {
      getUsers();
    }

    loadStorageData();
  },[])

  async function getUsers():Promise<void>{
    const config = {
      headers: {
        'Authorization' : `Bearer ${token}`
      }
    }

    const res = await api.get('/api/User/UserList', config);

    setUsers(res.data);
  }

  const handleSearch = useCallback(async (data: FilterSearchData) => {

    const { campopesquisar } = data;

    addToast({
      type: 'info',
      title: 'Pesquisa realizada',
      description: campopesquisar
    });

    console.log(campopesquisar);
  }, [])

  const updateUser = (userId: number, status: boolean) => {
  }

  return (
    <AppContainer>
      <Header />
      <Content>
        <SearchContainer>
          <h1>Gerenciar usuários:</h1>
          <Form ref={formRef} onSubmit={handleSearch}>
            <Input name="campopesquisar" icon={FiSearch} placeholder="E-mail / Descrição" />
            <Button type="submit" >Pesquisar</Button>
          </Form>
        </SearchContainer>

        <Table users={users} updateUser={updateUser} />

      </Content>
    </AppContainer>
  );

};

export default ManagerUsers;
