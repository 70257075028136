import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import signInBackgroundImg from '../../assets/sign-in-background.png';

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SearchContainer = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: flex-start;

  h1{
      color: #1c195d;
      margin-top: 24px;
    }

  form{
    width: 100%;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-between;

      input{
        width: 400px;
        margin-right: 20px;
      }

      button{
        width: 150px;
        margin-bottom: 15px;
        margin-left: 15px;
      }
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
`;

export const HeaderLine = styled.hr`
  /* style={{ marginTop: "25px", marginBottom: "25px", borderWidth: "3px", color: "grey" }} */
  margin: 25px 0px;
  border-width: 3px;
  color: "grey";
`;
