import React, { SelectHTMLAttributes, useEffect, useRef, useState, useCallback } from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import Select, { Props as ReactSelectProps } from 'react-select';

import { Container, Error } from './styles';
import Placeholder from 'react-select/src/components/Placeholder';

interface OptionsProps {
  id: number;
  description: string;
}

interface SelectProps extends ReactSelectProps<OptionsProps> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  options: Array<OptionsProps>;
  defaulSelection: OptionsProps;
};

const SelectCustom: React.FC<SelectProps> = ({ icon: Icon, name, options, defaulSelection, ...rest }) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name)
  const [currentValue, setCurrentValue] = useState('');

  const handleSelectFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleSelectBlur = useCallback(() => {
    setIsFocused(false)
    setIsFilled(!!selectRef.current)
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentValue(event.target.value.trim());
  };
  return (
    <Container
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      onFocus={handleSelectFocus}
      onBlur={handleSelectBlur}
      ref={selectRef}
      name={name}
      value={currentValue}
      onChange={handleChange}
    >
      {options.sort((a, b) => a.description.localeCompare(b.description)).map(o => (
        <option key={o.id} value={o.id}>{o.description}</option>
      ))}
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#C53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default SelectCustom;
