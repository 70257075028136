import React, {useCallback, useRef} from 'react'; //Usar useCallback
import { FiLock } from 'react-icons/fi';
import {FormHandles} from '@unform/core';
import {Form} from '@unform/web';
import * as Yup from 'yup'; //Biblioteca de validação
import { useHistory} from 'react-router-dom';
import {useAuth} from '../../hooks/auth';
import {useToast} from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import logoImg from '../../assets/logo.png';
import Input from '../../components/Input'
import Button from '../../components/Button'
import {Container, Content, AnimationContainer, Background} from './styles';

interface NewPasswordData{
  username: string;
  newPassword: string;
  confirmNewPassword: string;
}

const NewPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const {signIn} = useAuth();
  const {addToast} = useToast();

  const history = useHistory();

  const handleSubmit = useCallback(async (data: NewPasswordData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        newPassword: Yup.string().required('Nova senha obrigatória'),
        confirmNewPassword: Yup.string().required('Confirmação de nova senha obrigatória')
      });

      await schema.validate(data,{
        abortEarly: false,
      });

      const user = localStorage.getItem('@AppBern:user');
      const { newPassword, confirmNewPassword } = data;

      if (newPassword !== confirmNewPassword){
        addToast({
          type: 'error',
          title: 'Erro na solicitação!',
          description: 'Os campos Senha e Confirmação de nova senha não correspondem.'
        });

        return;
      }

      //Método de Recuperar senha
      console.log(data);

      addToast({
        type: 'success',
        title: 'Senha alterada com sucesso!',
        description: 'Senha alterada com sucesso.'
      });

      history.push('/');

    } catch (err) {
      if(err instanceof Yup.ValidationError){
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Erro na solicitação',
        description: 'Ocorrreu um erro ao realizar a solicitação'
      });
    }
  }, [signIn, addToast, history]);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Bernhoeft"/>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Criar nova senha</h1>

            <Input name="newPassword" icon={FiLock} placeholder="Nova senha" type="password"/>
            <Input name="confirmNewPassword" icon={FiLock} placeholder="Confirmar nova senha" type="password"/>

            <Button type="submit" >Enviar</Button>

            <a href="/">Voltar</a>
          </Form>

        </AnimationContainer>
      </Content>

      <Background />
    </Container>
  );
}

export default NewPassword;
