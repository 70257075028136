import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`

  background: #CBC8D4 ;
  border-radius: 10px;
  padding: 16px;
  width: 100%;

  border: 2px solid #CBC8D4;
  color: #666360;

  display: flex;
  align-items: center;

  & + div{
    margin-top: 8px;
  }


  ${props => props.isErrored && css`
    border-color: #c53030;
  `}

  ${props => props.isFocused && css`
    color: #1C195D;
    border-color: #1C195D;
  `}

  ${props => props.isFilled && css`
    color: #1C195D;
  `}

  input{
    flex: 1;
    background: transparent;
    border: 0;
    color: #1C195D;

    &::placeholder{
      color: #666360;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg{
    margin:0;
  }

  span{
    background: #C53030;
    color: #FFF;

    &::before{
      border-color: #C53030 transparent;
    }
  }

`;
