import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';
import jwt_decode from 'jwt-decode';

interface SignInCredentials {
  userName: string,
  password: string;
}

interface AuthState {
  token: string;
  user: object;
  userRole: string;
};

interface AuthContextData {
  user: object;
  userRole: string;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@AppBern:token');
    const user = localStorage.getItem('@AppBern:user');
    if (token && user) {
      const { role } = jwt_decode(token);
      const userRole = role;

      console.log(userRole);

      return { token, user: JSON.parse(user), userRole };      
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ userName, password }) => {
    const response = await api.post('api/User/login', {
      userName,
      password
    });

    let userRole = null;

    const { token, user } = response.data;

    if(!!token){
      const { role } = jwt_decode(token);
      userRole = role;
    }

    if( userRole === "Office" || userRole === "Customer"){
      return;
    }

    localStorage.setItem('@AppBern:token', token);
    localStorage.setItem('@AppBern:user', JSON.stringify(user));
    const { email } = jwt_decode(token);
    localStorage.setItem('@AppBern:email', email);

    setData({ token, user, userRole });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@AppBern:token');
    localStorage.removeItem('@AppBern:user');
    localStorage.removeItem('@AppBern:email');

    setData({} as AuthState);
  }, [])

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut, userRole: data.userRole }}>
      {children}
    </AuthContext.Provider>
  )
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
