import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import signInBackgroundImg from '../../assets/sign-in-background.png';


export const HeaderContainer = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  background-color: #003C78;
  height: 120px;
  padding: 0px 1rem;

  justify-content: space-between;

  border-radius: 0 0 15px 15px;
  width: 85%;

  img{
    height: 10vh;
    margin-left:10px;
    margin-top:10px;
  }
`;

export const HeaderOptions = styled.div`
  a{
    color: #FFF;
    margin-right: 10px;
    margin-left: 10px;
    display: block;

    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg{
      margin-right: 5px;
      align-self: center;
    }

    &:hover{
        color: ${shade(0.2, '#FFF')}
      }
  }
`;
