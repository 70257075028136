import React, {useCallback, useRef} from 'react'; //Usar useCallback
import { FiMail } from 'react-icons/fi';
import {FormHandles} from '@unform/core';
import {Form} from '@unform/web';
import * as Yup from 'yup'; //Biblioteca de validação
import { useHistory} from 'react-router-dom';
import {useToast} from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import logoImg from '../../assets/logo.png';
import Input from '../../components/Input'
import Button from '../../components/Button'
import {Container, Content, AnimationContainer, Background} from './styles';
import api from '../../services/api';

interface RecoverPasswordData{
  email: string;
}

const RecoverPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const {addToast} = useToast();

  const history = useHistory();

  const handleSubmit = useCallback(async (data: RecoverPasswordData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string().required('E-mail obrigatório').email('Digite um e-mail válido')
      });

      await schema.validate(data,{
        abortEarly: false,
      });

      await api.get('api/User/RecoverPassword?email=' + data.email);

      addToast({
        type: 'success',
        title: 'Solicitação Realizada!',
        description: 'Cheque seu e-mail!'
      });

      history.push('/');

    } catch (err) {
      if(err instanceof Yup.ValidationError){
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Erro na solicitação',
        description: 'Ocorrreu um erro ao realizar a solicitação'
      });
    }
  }, [addToast, history]);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Bernhoeft"/>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Digite seu e-mail</h1>

            <Input name="email" icon={FiMail} placeholder="E-mail"/>

            <Button type="submit" >Enviar</Button>

            <a href="/">Voltar</a>
          </Form>

        </AnimationContainer>
      </Content>

      <Background />
    </Container>
  );
}

export default RecoverPassword;
