import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 80%;

  img{
    align-self: center;
    height: 40vh;
    margin-left:30px;
    margin-right:30px;
    margin-top:10px;
  }

`;
